import russianLocaleSettings from 'antd/locale/ru_RU';
import englishLocaleSettings from 'antd/locale/en_GB';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import packageJson from '../package.json';
import { App } from './App';
import { KurentoProvider } from './contexts/kurento.context';
import { WebsocketProvider } from './contexts/ws.context';
import reportWebVitals from './reportWebVitals';
import { ThemeWrapper } from './components/ThemeWrapper/ThemeWrapper';
import store from './store/Store';
import './styles/index.scss';
import './utils/i18n';
import * as process from "process";

const russianLocale = {
    ...russianLocaleSettings,
    Pagination: {
        ...russianLocaleSettings.Pagination,
        items_per_page: 'на странице',
    },
};

const englishLocale = {
    ...englishLocaleSettings,
    Pagination: {
        ...englishLocaleSettings.Pagination,
        items_per_page: 'on page',
    },
};

console.log('Version: ' + packageJson.version);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <WebsocketProvider>
                <KurentoProvider>
                    <ThemeWrapper locale={process.env.REACT_APP_DEFAULT_LANGUAGE === 'en' ? englishLocale : russianLocale}>
                        <App />
                    </ThemeWrapper>
                </KurentoProvider>
            </WebsocketProvider>
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();